.cart-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden-container {
  height: 80vh;
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 1rem black;
  overflow: hidden;
}

.cart-items-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 1rem black;
  overflow-y: auto;
}

.cart-item-content {
  display: flex;
  width: 100%;
}

.cart-empty {
  font-size: 2rem;
  text-align: center;
  transform: translateY(150%);
}

.cart-item-title {
  text-align: center;
}

.cart-item-price {
  text-align: right;
}

.cart-item-quantity,
.cart-item-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-item-quantity > input {
  display: block;
  width: 10%;
  margin: auto;
  text-align: center;
}

.cart-item-remove-button {
  background-color: transparent;
  border: none;
  color: red;
  margin: 0.5rem;
  text-align: right;
}

.cart-item-remove-button:hover {
  cursor: pointer;
}

.cart-item-title,
.cart-item-price,
.cart-item-quantity,
.cart-item-remove-button {
  flex: 1;
}

.cart-checkout-button {
  height: 3rem;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 1rem;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;

  outline: none;
}

.cart-checkout-button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

/* Customize the scrollbar track */
::-webkit-scrollbar {
  width: 1.5em; /* Width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #888; /* Background color of the thumb */
  border-radius: 1rem; /* Rounded corners for the thumb */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
