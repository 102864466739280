.bg {
  height: 100vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: white;
  font-size: 3.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
}
