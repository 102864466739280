.home-slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-title {
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: black;
}

.home-title {
  font-size: 5rem;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  letter-spacing: 0.5rem;
  text-shadow: 0 0 1rem black;
}

.slide {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
}

.slide-price {
  font-size: 2.25em;
  text-align: center;
  color: white;
}

.slide-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Keyframes for showing the component */
@keyframes showPopup {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  10% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0.1;
  }
  20% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0.2;
  }
  30% {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0.3;
  }
  40% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0.4;
  }
  50% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.5;
  }
  60% {
    transform: translate(-50%, -50%) scale(0.6);
    opacity: 0.6;
  }
  70% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }
  80% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.8;
  }
  90% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

/* Keyframes for hiding the component */
@keyframes hidePopup {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  20% {
    transform: translate(-50%, -50%) scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0.4;
  }
  60% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0.2;
  }
  80% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}

.success-popup {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 60%;
  height: 30%;
  background-color: white;
  border-radius: 1rem;
  opacity: 1;
  animation: showPopup 0.25s ease-in-out forwards;
}

.invisible {
  opacity: 0;
  transform: scale(0);
  animation: hidePopup 0.5 ease-in-out forwards;
}

.success-title {
  color: black;
  text-align: center;
  z-index: 10001;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: black;
  margin: 0.5rem;
}

.close-button:hover {
  color: red;
  cursor: pointer;
}
.slide-content-container {
  height: 60vh;
  width: 40%;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: 0 0 1rem black;
  position: relative;
}

.slide-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: white;
  margin: 0.5rem;
}

.button-black {
  color: black;
}

.slide-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.container-black {
  background-color: black;
  box-shadow: 0 0 1rem white;
}

.slide-img-container {
  height: 70%;
  width: 60%;
  margin: auto;
}

.slide-img {
  display: block;
  width: 80%;
  margin: auto;
  height: 100%;
  //object-fit: cover;
  object-position: center;
  border-radius: 1rem;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-form {
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slide-input {
  width: 30%;
  margin: auto;
  border: none;
  letter-spacing: 0px;
  border-bottom: 1px solid black;
  background-color: transparent;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 0 1rem black;
  color: white;
  outline: none;
}

.button-container-left {
  flex: 1;
  display: flex;
  align-items: right;
  justify-content: center;
}
.button-container-right {
  flex: 1;
  display: flex;
  align-items: left;
  justify-content: center;
}

.increase-button,
.decrease-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  text-shadow: 0 0 1rem black;
}

.increase-button:hover,
.decrease-button:hover {
  cursor: pointer;
}

.input-black {
  color: black;
  text-shadow: 0 0 1rem white;
}

.slide-title {
  margin-bottom: 4rem;
  text-align: center;
  font-size: 1.5rem;
}

.sizes {
  padding-top: 0.8rem;
  display: flex;
  z-index: 1000;
}

.size-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*media queries*/
@media (max-width: 768px) {
  .home-title {
    font-size: 3rem;
  }
  .slide-content-container {
    width: 80%;
  }
  .slide-img-container {
    width: 80%;
    height: 60%;
  }
}
